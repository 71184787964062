export const MenuItemsPTBRTranslations = {
  menuItemsCasesPersonalCareDescription: 'Desburocratizando o S&OP',
  menuItemsCasesAutomobilesDescription:
    'Aumento de 45,7% na vendas de caminhões',
  menuItemsCasesFoodDescription: 'Aumento de 26% na receita da companhia',
  menuItemsCasesLogisticsDescription:
    'Aumento de 10% no total de cargas transportadas',

  menuItemsResourcesBlogDescription:
    'Fique atualizado com nossas últimas notícias',
  menuItemsResourcesHelpCenterDescription:
    'Respostas para perguntas sobre o produto',

  menuItemsProductEconomicDataDescription:
    'Diversos indicadores para apoiar o planejamento',
  menuItemsProductModelsDescription: 'Projeções impulsionadas por IA preditiva',
  menuItemsProductPlanningDescription:
    'Tomada de decisão colaborativa entre times',
};
