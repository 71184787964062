import React, { useEffect } from 'react';

import { Check, Info, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import plansBackground from 'src/assets/plans-background.png';
import { Button } from 'src/components/Button';
import { Clients } from 'src/components/Clients';
import { ContactSales } from 'src/components/ContactSales';
import { Head } from 'src/components/Head';
import { Title } from 'src/components/Title/styles';
import { RootState } from 'src/redux/store';
import light from 'src/styles/themes/light';
import {
  TrackGoogleAnalyticsEvent,
  TrackGoogleAnalyticsPageView,
} from 'src/utils/google-analytics';

import {
  Container,
  Content,
  TableContent,
  PlansType,
  Table,
  CardsContainer,
  PlanCard,
  Background,
} from './styles';

export function Plans() {
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    TrackGoogleAnalyticsPageView(location.pathname, 'Tela de Planos');
  }, [location.pathname]);

  return (
    <Container>
      <Head
        title={`${translate('plans')} | 4intelligence`}
        description={translate('plansPageHeadDescription') ?? ''}
      />

      <Title style={{ padding: '0 2rem' }}>
        <h1>{translate('plansPageTitle')}</h1>
      </Title>

      <Background src={plansBackground} alt="background" />

      <Content>
        <CardsContainer>
          <PlanCard>
            <h3>Free</h3>
            <p>{translate('plansPageFreeDescription')}</p>

            <Button
              buttonType="secondary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Comece grátis',
                  'Ir para cadastro do freemium',
                  location.pathname,
                );
                navigate(`${urlPrefix}/freemium/sign-up`);
              }}
              data-testid="start-free-button"
            >
              {translate('plansPageFreeButton')}
            </Button>
          </PlanCard>

          <PlanCard>
            <h3>Data Starter</h3>
            <p>{translate('plansPageStarterDescription')}</p>
            <Button
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Falar com vendas - Starter',
                  'Ir para tela Falar com Vendas',
                  location.pathname,
                );
                navigate(`${urlPrefix}/contact-sales`);
              }}
              data-testid="contact-sales-plans-button"
            >
              {translate('plansPageStarterButton')}
            </Button>
          </PlanCard>

          <PlanCard>
            <h3>Essential</h3>
            <p>{translate('plansPageEssentialDescription')}</p>
            <Button
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Falar com vendas - Essential',
                  'Ir para tela Falar com Vendas',
                  location.pathname,
                );
                navigate(`${urlPrefix}/contact-sales`);
              }}
              data-testid="contact-sales-plans-button"
            >
              {translate('plansPageEssentialButton')}
            </Button>
          </PlanCard>

          <PlanCard>
            <h3>Business Critical</h3>
            <p>{translate('plansPageBusinessDescription')}</p>
            <Button
              buttonType="primary"
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  'Falar com vendas - Business',
                  'Ir para tela Falar com Vendas',
                  location.pathname,
                );
                navigate(`${urlPrefix}/contact-sales`);
              }}
              data-testid="contact-sales-plans-button"
            >
              {translate('plansPageBusinessButton')}
            </Button>
          </PlanCard>
        </CardsContainer>

        <Clients />

        <TableContent>
          <Title style={{ padding: '0 2rem' }}>
            <h2>{translate('plansPageTableTitle')}</h2>
          </Title>

          <Table>
            <thead>
              <tr>
                <th aria-label="nome da coluna vazia" />
                <th>
                  <PlansType>
                    <h2>
                      Free <br /> &nbsp;
                    </h2>

                    <Button
                      buttonType="secondary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Comece grátis',
                          'Ir para cadastro do freemium',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/freemium/sign-up`);
                      }}
                      data-testid="start-free-button"
                    >
                      {translate('plansPageFreeButton')}
                    </Button>
                  </PlansType>
                </th>
                <th>
                  <PlansType>
                    <h2>
                      Data <br /> Starter
                    </h2>

                    <Button
                      buttonType="primary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Falar com vendas - Starter',
                          'Ir para tela Falar com Vendas',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/contact-sales`);
                      }}
                      data-testid="contact-sales-plans-button"
                    >
                      {translate('plansPageStarterButton')}
                    </Button>
                  </PlansType>
                </th>
                <th>
                  <PlansType>
                    <h2>
                      Essential <br /> &nbsp;
                    </h2>

                    <Button
                      buttonType="primary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Falar com vendas - Essential',
                          'Ir para tela Falar com Vendas',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/contact-sales`);
                      }}
                      data-testid="contact-sales-plans-button"
                    >
                      {translate('plansPageEssentialButton')}
                    </Button>
                  </PlansType>
                </th>
                <th>
                  <PlansType>
                    <h2>
                      Business <br /> Critical
                    </h2>

                    <Button
                      buttonType="primary"
                      onClick={() => {
                        TrackGoogleAnalyticsEvent(
                          'Falar com vendas - Business',
                          'Ir para tela Falar com Vendas',
                          location.pathname,
                        );
                        navigate(`${urlPrefix}/contact-sales`);
                      }}
                      data-testid="contact-sales-plans-button"
                    >
                      {translate('plansPageBusinessButton')}
                    </Button>
                  </PlansType>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainDataTitle')}</h2>
                  <p>{translate('plansExplainDataDescription')}</p>
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageRow0Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageRow1Name')}</td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow1Free')}
                >
                  <p>{translate('plansPageRow1Free')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow1Free')}
                  />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow2Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow3Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow4Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow5Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow6Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow7Name')}</td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.purple4} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.primary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow8Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainPrototypeTitle')}</h2>
                  <p>{translate('plansExplainPrototypeDescription')}</p>
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageRow9Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageRow10Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow11Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow12Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow13Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow14Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow15Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow16Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow17Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow17Essential')}
                >
                  <p>{translate('plansPageRow17Essential')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow17Essential')}
                  />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow17Business')}
                >
                  <p>{translate('plansPageRow17Business')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow17Business')}
                  />
                </td>
              </tr>

              <tr className="table-explain">
                <td colSpan={5}>
                  <h2>{translate('plansExplainProductionTitle')}</h2>
                  <p>{translate('plansExplainProductionDescription')}</p>
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageRow18Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageRow19Name')}</td>

                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow20Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow21Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageRow22Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageRow23Name')}</td>

                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow24Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr className="table-title">
                <td colSpan={5}>{translate('plansPageRow25Name')}</td>
              </tr>

              <tr>
                <td>{translate('plansPageRow26Name')}</td>

                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow27Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.secondary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr>
                <td>{translate('plansPageRow28Name')}</td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder">
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.secondary} />
                </td>
                <td aria-label="placeholder">
                  <Check weight="bold" color={light.colors.green4} />
                </td>
              </tr>

              <tr style={{ textAlign: 'start' }}>
                <td>{translate('plansPageRow29Name')}</td>
                <td aria-label="placeholder" style={{ textAlign: 'center' }}>
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td aria-label="placeholder" style={{ textAlign: 'center' }}>
                  <X weight="bold" color={`${light.colors.primary}7A`} />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow29Essential')}
                  style={{ verticalAlign: 'text-top' }}
                >
                  <p>{translate('plansPageRow29Essential')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow29Essential')}
                  />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow29Business')}
                >
                  <p>{translate('plansPageRow29Business')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow29Business')}
                  />
                </td>
              </tr>

              <tr style={{ textAlign: 'start' }}>
                <td>{translate('plansPageRow30Name')}</td>
                <td aria-label="placeholder" style={{ textAlign: 'center' }}>
                  <X weight="bold" color={`${light.colors.purple4}7A`} />
                </td>
                <td
                  style={{ verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow30Starter')}
                >
                  <p>{translate('plansPageRow30Starter')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow30Starter')}
                  />
                </td>
                <td
                  style={{ verticalAlign: 'text-top' }}
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow30Essential')}
                >
                  <p>{translate('plansPageRow30Essential')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow30Essential')}
                  />
                </td>
                <td
                  className="table-text"
                  data-tooltip-id="plans-tooltip"
                  data-tooltip-content={translate('plansPageRow30Business')}
                >
                  <p>{translate('plansPageRow30Business')}</p>
                  <Info
                    data-tooltip-id="plans-tooltip"
                    data-tooltip-content={translate('plansPageRow30Business')}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </TableContent>
      </Content>

      <Tooltip id="plans-tooltip" />

      <ContactSales />
    </Container>
  );
}
