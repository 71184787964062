import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from 'src/redux/store';
import { TrackGoogleAnalyticsEvent } from 'src/utils/google-analytics';

import { Container, Icon, Information, Menu, MenuContent } from './styles';
import { SubmenuProps } from './types';

export function Submenu({
  menu,
  visible,
  triangleLeftPosition,
  ...props
}: SubmenuProps) {
  const location = useLocation();
  const { t: translate } = useTranslation();
  const { urlPrefix } = useSelector((state: RootState) => state.config);

  return (
    <Menu>
      <Container
        visible={visible}
        triangleLeftPosition={triangleLeftPosition}
        {...props}
      >
        <MenuContent>
          {menu.map((item) => (
            <Link
              key={item.id}
              to={item.id === 'blog' ? item.url : urlPrefix + item.url}
              target={item.id === 'blog' ? '_blank' : '_self'}
              data-testid={`menu-${item.id}-link`}
              onClick={() => {
                TrackGoogleAnalyticsEvent(
                  `Menu - ${translate(item.title, { lng: 'pt-BR' })}`,
                  `Ir para a tela ${translate(item.title, { lng: 'pt-BR' })}`,
                  location.pathname,
                );
              }}
            >
              <Icon color={item.color} data-testid={`menu-${item.id}-icon`}>
                {item.icon}
              </Icon>

              <Information>
                <h2 data-testid={`menu-${item.id}-title`}>
                  {translate(item.title)}
                </h2>
                <p data-testid={`menu-${item.id}-description`}>
                  {translate(item.description)}
                </p>
              </Information>
            </Link>
          ))}
        </MenuContent>
      </Container>
    </Menu>
  );
}
