export const PlansENUSTranslations = {
  plansPageHeadDescription:
    'Find out about our plans. See which plan works best for your business.',

  plansPageTitle: 'Plans',
  plansPageTableTitle: 'See how our plans compare',

  plansPageFreeDescription:
    'For a single user needing to access a limited amount of economic data for free.',
  plansPageFreeButton: 'Sign Up',

  plansPageStarterDescription:
    'For a single user needing to access a wide range of economic data and projections.',
  plansPageStarterButton: 'Contact Us',

  plansPageEssentialDescription:
    'For a single user needing economic data and a model prototyping environment, including set-up and individual support.',
  plansPageEssentialButton: 'Contact Us',

  plansPageBusinessDescription:
    'For multiple users needing all the functionality of the Essential plan, with additional collaboration and governance features, and advanced support.',
  plansPageBusinessButton: 'Contact Us',

  plansExplainDataTitle: 'Data',
  plansExplainDataDescription: 'Economic data and projections',

  plansPageRow0Name: 'Feature Store',
  plansPageRow1Name: 'Access over 1200 indicators',
  plansPageRow1Free: 'Limited',
  plansPageRow2Name: 'Data Alerts',
  plansPageRow3Name: 'Projections Alerts',
  plansPageRow4Name: 'Creation of Customized Monitoring Groups',
  plansPageRow5Name: 'Export Data in Tabular Formats',
  plansPageRow6Name: 'Chat 4i',
  plansPageRow7Name: 'Analytical Reports',
  plansPageRow8Name: 'Rest API for Building Automated Pipelines',
  // plansPageRow9Name: 'Share Groups and Features',

  plansExplainPrototypeTitle: 'Prototyping',
  plansExplainPrototypeDescription:
    'Essential activities to obtain accurate predictive models automatically.',

  plansPageRow9Name: 'Model Construction (Prototyping)',
  plansPageRow10Name: 'Automatic Feature Discovery (Pick4me)',
  plansPageRow11Name: 'Classification Models',
  plansPageRow12Name: 'Time Series Models',
  plansPageRow13Name: 'Model Composition',
  plansPageRow14Name: 'Model Updates',
  plansPageRow15Name: 'Scenario Construction',
  plansPageRow16Name: 'Explainability of Results and Projections',
  // plansPageRow18Name: 'One-Click Modeling',
  // plansPageRow19Name: 'Single Model - Multiple Horizons',
  // plansPageRow20Name: 'Hierarchical Models',
  plansPageRow17Name: 'Modeling Limit per Year',
  plansPageRow17Essential: '120 or 600',
  plansPageRow17Business: 'Unlimited',

  plansExplainProductionTitle: 'Production',
  plansExplainProductionDescription:
    'Critical functionalities for sharing and practical use of projections in business environments, allowing monitoring of their life cycle and governance under changing scenarios.',

  plansPageRow18Name: 'Governance',
  plansPageRow19Name: 'Approval Flows',
  plansPageRow20Name: 'Accuracy Monitoring',
  plansPageRow21Name: 'Version Tracking',
  // plansPageRow26Name: 'Automatic Compliance Repor',
  plansPageRow22Name: 'Workspace',
  plansPageRow23Name: 'Share with Other Users',
  plansPageRow24Name: 'Role-based access',
  // plansPageRow30Name: 'Consistency of multi-level (hierarchical) scenarios',
  plansPageRow25Name: 'Ecosystem (Use and Safety)',
  plansPageRow26Name: 'Rest APIs for Modeling',
  plansPageRow27Name: 'SSO',
  plansPageRow28Name: 'Automatic Encryption of all Data',
  plansPageRow29Name: 'Support',
  plansPageRow29Essential: '9am-5pm Email SLA Response Time: 24 hours',
  plansPageRow29Business: '9am-5pm Email & Teams SLA Response Time: 4 hours',
  plansPageRow30Name: 'Customer Service',
  plansPageRow30Starter:
    'Email support to answer questions on economic scenarios',
  plansPageRow30Essential: 'Email support for questions about the platform',
  plansPageRow30Business:
    'Service as provided in the Essential plan + additional support from data scientists, econometricians and sector specialists to build predictive models and monitor their life cycle',

  expandRow0Title: 'Open/close feature store',
};
