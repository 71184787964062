export const PlansPTBRTranslations = {
  plansPageHeadDescription:
    'Conheça nossos planos. Veja qual plano funciona melhor para o seu negócio.',

  plansPageTitle: 'Planos',
  plansPageTableTitle: 'Veja como nossos planos se comparam',

  plansPageFreeDescription:
    'Para um único usuário que precisa acessar gratuitamente uma quantidade limitada de dados econômicos.',
  plansPageFreeButton: 'Comece grátis',

  plansPageStarterDescription:
    'Para um único usuário que precisa acessar uma ampla variedade de dados e projeções econômicas.',
  plansPageStarterButton: 'Fale conosco',

  plansPageEssentialDescription:
    'Para um único usuário que necessita de dados econômicos e um ambiente de prototipagem de modelo, incluindo configuração e suporte individual.',
  plansPageEssentialButton: 'Fale conosco',

  plansPageBusinessDescription:
    'Para vários usuários que precisam de tudo no plano Essential, com recursos adicionais de colaboração, governança e suporte avançado. ',
  plansPageBusinessButton: 'Fale conosco',

  plansExplainDataTitle: 'Dados',
  plansExplainDataDescription: 'Dados econômicos e projeções.',

  plansPageRow0Name: 'Feature Store',
  plansPageRow1Name: 'Acesso a mais de 1200 indicadores',
  plansPageRow1Free: 'Limitado',
  plansPageRow2Name: 'Alertas de novas divulgações',
  plansPageRow3Name: 'Alertas de novas projeções',
  plansPageRow4Name: 'Criação de grupos de acompanhamento customizados',
  plansPageRow5Name: 'Exportação de dados em formatos tabulares',
  plansPageRow6Name: 'Chat 4i',
  plansPageRow7Name: 'Relatórios Analíticos',
  plansPageRow8Name: 'Biblioteca para construção de pipelines automatizados',
  // plansPageRow9Name: 'Compartilhamento de grupos e features',

  plansExplainPrototypeTitle: 'Prototipação',
  plansExplainPrototypeDescription:
    'Atividades essenciais para obtenção de modelos preditivos acurados de forma automática.',

  plansPageRow9Name: 'Construção de Modelos (Prototipação)',
  plansPageRow10Name: 'Descoberta automática de features (Pick4me)',
  plansPageRow11Name: 'Modelos de Classificação',
  plansPageRow12Name: 'Modelos de Séries de Tempo',
  plansPageRow13Name: 'Composição de Modelos',
  plansPageRow14Name: 'Update de Modelos',
  plansPageRow15Name: 'Construção de Cenários',
  plansPageRow16Name: 'Explicabilidade de resultados e projeções',
  // plansPageRow18Name: 'One-Click Modeling',
  // plansPageRow19Name: 'Single Model - Multiple Horizons',
  // plansPageRow20Name: 'Modelos Hierárquicos',
  plansPageRow17Name: 'Limite de modelagens por ano',
  plansPageRow17Essential: '120 ou 600',
  plansPageRow17Business: 'Ilimitado',

  plansExplainProductionTitle: 'Produção',
  plansExplainProductionDescription:
    'Funcionalidades críticas para compartilhamento e utilização prática das projeções em ambientes de negócio, permitindo o acompanhamento de seu ciclo de vida e governança sob modificações de cenários.',

  plansPageRow18Name: 'Governança',
  plansPageRow19Name: 'Fluxos de aprovação',
  plansPageRow20Name: 'Acompanhamento de Acurácia',
  plansPageRow21Name: 'Tracking de versões',
  // plansPageRow26Name: 'Report automático de compliance',
  plansPageRow22Name: 'Workspace',
  plansPageRow23Name: 'Compartilhamento com outros usuários',
  plansPageRow24Name: 'Acesso baseado em função',
  // plansPageRow30Name: 'Consistência de cenários multi-nível (hierárquicos)',
  plansPageRow25Name: 'Ecossistema (Utilização e Segurança)',
  plansPageRow26Name: 'Rest APIs para Modelagem',
  plansPageRow27Name: 'SSO',
  plansPageRow28Name: 'Criptografia automática de todos os dados',
  plansPageRow29Name: 'Suporte',
  plansPageRow29Essential: '9x5 por Email SLA de resposta: 24 horas',
  plansPageRow29Business: '9x5 por Email e Teams SLA de resposta: 4 horas',
  plansPageRow30Name: 'Atendimento',
  plansPageRow30Starter:
    'Atendimento por email para retirar dúvidas dos cenários econômicos',
  plansPageRow30Essential:
    'Atendimento por email para retirar dúvidas da plataforma',
  plansPageRow30Business:
    'Atendimento previsto no plano essencial + suporte de cientistas de dados, econometristas e especialistas setoriais para construção de modelos preditivos e acompanhamento de seu ciclo de vida',

  expandRow0Title: 'Abrir/fechar feature store',
};
