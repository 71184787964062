export const MenuItemsENUSTranslations = {
  menuItemsCasesPersonalCareDescription: 'Optimizing S&OP processes',
  menuItemsCasesAutomobilesDescription: '45% increase in sales',
  menuItemsCasesFoodDescription: '26% increase in revenues',
  menuItemsCasesLogisticsDescription: '10% increase in cargo deliveries',

  menuItemsResourcesBlogDescription: 'Stay updated with our latest news',
  menuItemsResourcesHelpCenterDescription:
    'Answers to questions about the product',

  menuItemsProductEconomicDataDescription:
    'Wide range of indicators to support planning',
  menuItemsProductModelsDescription: 'AI-driven predictive modelling',
  menuItemsProductPlanningDescription:
    'Collaborative decision-making across teams',
};
